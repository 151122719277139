<template>
  <v-container>
    <v-card>
      <v-card-title class="accent text-h5 font-weight-bold pa-3"
        ><v-icon color="primary" left>
          mdi-comment-text-multiple-outline
        </v-icon>
        Comentarios
        <v-spacer></v-spacer>
        <v-btn
          @click="dialogAssuntos = true"
          absolute
          right
          color="secondary"
          class="white--text"
        >
          Assuntos
          <v-icon right> mdi-comment-alert-outline</v-icon>
        </v-btn>
        <DialogAssuntos
          v-if="dialogAssuntos"
          :dialogAssuntos.sync="dialogAssuntos"
        />
      </v-card-title>

      <v-tabs fixed-tabs v-model="tab">
        <v-tab>Não Lidas</v-tab>
        <v-tab>Lidas</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <Comentarios v-if="tab === 0" :status="0" />
        </v-tab-item>

        <v-tab-item>
          <Comentarios v-if="tab === 1" :status="1" />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "ComentariosTabs",

  data() {
    return {
      tab: 0,
      dialogAssuntos: false,
    };
  },

  components: {
    Comentarios: () => import("./Comentarios.vue"),
    DialogAssuntos: () => import("./components/DialogAssuntos.vue"),
  },
};
</script>

<style></style>
